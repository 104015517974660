import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'
import BackToTopButton from "../components/BackToTopButton";

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Inferix.inc</title>
        <meta property="og:title" content="Inferix.inc" />
      </Helmet>
      <section className="home-hero">
        <video
          src="https://presentation-website-assets.teleporthq.io/templates/togthr/togthr-video.mp4"
          loop
          muted
          poster="/hero-bg.png"
          preload="auto"
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <img alt="image" src="/inferix-logo.svg" className="home-branding" />
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <div className="home-hamburger"  onClick={()=>{
              const element = document.getElementById("my-menu");
              element.classList.add("teleport-show");
              element.classList.add("thq-show");
              element.classList.add("thq-translate-to-default");
            }}>
              <img alt="image" src="/hamburger.svg"    className="home-icon" />
            </div>
          </div>
          <div data-thq="thq-mobile-menu" id="my-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container1">
                <img
                  alt="image"
                  src="/inferix-logo.svg"
                  className="home-image"
                />
                <div data-thq="thq-close-menu" className="home-menu-close" onClick={()=>{
                  const element = document.getElementById("my-menu");
                  element.classList.remove("teleport-show");
                  element.classList.remove("thq-show");
                  element.classList.remove("thq-translate-to-default");
                }}>
                  <svg viewBox="0 0 1024 1024" className="home-icon01">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <span className="home-text">About</span>
                <span className="home-text01">Services</span>
                <span className="home-text02">Pricing</span>
                <span className="home-text03">Team</span>
                <span className="home-text04">Blog</span>
              </nav>
              <div className="home-container2">
                <button className="home-login button">Login</button>
                <button className="button">Register</button>
              </div>
            </div>
            <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon03">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon05">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon07">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
        <div className="home-hero-content">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading">
                <span>
                  Decentralized
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text06">Visual Computing</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption">
              The best 3D rendering and AI inference infrastructure using
              crowdsourced GPUs
            </p>
          </div>
          <a
            href="https://inferix.io/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link button"
          >
            <span>
              <span>Get started today</span>
              <br></br>
            </span>
          </a>
        </div>
      </section>
      <section className="home-note">
        <h2 className="home-caption01">
          An innovated platform for 3D rendering and AI inference using
          crowdsourced GPUs globally
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header01">1.5M+</h3>
            <span className="home-caption02">Images rendered per year</span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header02">260K+</h3>
            <span className="home-caption03">Users</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header03">20k+</h3>
            <span className="home-caption04">Professional Artists</span>
          </div>
        </div>
      </section>
      <section className="home-slider">
        <div className="home-header04">
          <h2 className="home-heading1">Decentralized Rendering</h2>
        </div>
        <div className="home-selector">
          <p className="home-caption05">
            An innovated platform for 3D rendering and AI inference
            using crowdsourced globally
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </p>
          <div className="home-slide-titles">
            <div className="slide-title">
              <span>Media &amp; VFX</span>
            </div>
            <div className="slide-title slide-title-active">
              <span>
                Architecture
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="slide-title">
              <span>
                AR &amp; VR
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="slide-title">
              <span>Game Content</span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-slides">
        <div className="home-slider1 slider blaze-slider">
          <div className="home-slider-container blaze-container">
            <div className="home-slider-track-container blaze-track-container">
              <div className="home-slider-track blaze-track">
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/mediavfx-400h.png"
                    className="home-image1"
                  />
                  <div className="home-content1">
                    <div className="home-header05">
                      <h3 className="home-heading2">Media &amp; VFX</h3>
                      <p className="home-caption06">
                        Numerous films and TV shows use 3D graphics and visual
                        effects for immersive visual experiences
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more">
                      <span className="home-caption07">Read More</span>
                      <svg viewBox="0 0 1024 1024" className="home-icon09">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/architecture-400h.png"
                    className="home-image2"
                  />
                  <div className="home-content2">
                    <div className="home-header06">
                      <h3 className="home-heading3">
                        Architecture &amp; Design
                      </h3>
                      <p className="home-caption08">
                        Visualize and test product appearance and functionality
                        through 3D modeling and simulation
                      </p>
                    </div>
                    <div className="home-more1">
                      <a
                        href="https://inferix.io/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-caption09"
                      >
                        Read More
                      </a>
                      <a
                        href="https://inferix.io/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="home-link1"
                      >
                        <svg viewBox="0 0 1024 1024" className="home-icon11">
                          <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/vrar-400h.png"
                    className="home-image3"
                  />
                  <div className="home-content3">
                    <div className="home-header07">
                      <h3 className="home-heading4">VR &amp; AR</h3>
                      <p className="home-caption10">
                        The widespread adoption of content creation and
                        utilization is driven by the heightened realism offered
                        by VR/AR
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more2">
                      <span className="home-caption11">Read More</span>
                      <svg viewBox="0 0 1024 1024" className="home-icon13">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/gamecontent-400h.png"
                    className="home-image4"
                  />
                  <div className="home-content4">
                    <div className="home-header08">
                      <h3 className="home-heading5">Game Content</h3>
                      <p className="home-caption12">
                        Enhance gaming environments with 3D graphics and
                        animations to provide users with a richer gameplay
                        experience
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more3">
                      <span className="home-caption13">Read More</span>
                      <svg viewBox="0 0 1024 1024" className="home-icon15">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-controls">
              <button
                data-role="previous-banner-button"
                className="home-previous blaze-prev button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon17">
                  <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <button
                data-role="next-banner-button"
                className="home-next blaze-next button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon19">
                  <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="home-container4">
            <Script
              html={`<script>
const el = document.querySelector('.blaze-slider')

new BlazeSlider(el, {
  all: {
    slidesToShow: 3,
    slideGap: '80px',
    loop: true,
    enableAutoplay: true,
  },
})
</script>`}
            ></Script>
          </div>
        </div>
      </section>
      <section className="home-objectives"></section>
      <section className="home-create">
        <div className="home-content5">
          <div className="home-header09">
            <h2 className="home-heading6">
              <span>Join Inferix Network now!</span>
              <br></br>
            </h2>
          </div>
          <button className="home-button button">
            <span>
              <span>Get started today</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content6">
          <div className="home-main">
            <div className="home-branding1">
              <img
                alt="image"
                src="/inferix-logo.svg"
                className="home-image5"
              />
              <span className="home-text20">
                An innovated platform for 3D rendering and AI inference using
                crowdsourced GPUs globally
              </span>
            </div>
            <div className="home-links">
              <div className="home-column">
                <span className="home-header10">Company</span>
                <div className="home-list">
                  <Link to="/" className="home-navlink">
                    About
                  </Link>
                  <Link to="/" className="home-navlink1">
                    Services
                  </Link>
                  <Link to="/" className="home-navlink2">
                    How
                  </Link>
                  <Link to="/" className="home-navlink3">
                    <span className="home-text21">Why</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="home-column1">
                <span className="home-header11">Extern</span>
                <div className="home-list1">
                  <Link to="/" className="home-navlink4">
                    News
                  </Link>
                  <a
                    href="https://medium.com/@inferixgpu"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    Articles
                  </a>
                  <Link to="/" className="home-navlink5">
                    Privacy
                  </Link>
                  <Link to="/" className="home-navlink6">
                    Terms
                  </Link>
                  <Link to="/" className="home-navlink7">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="home-column2">
                <span className="home-header12">Social</span>
                <div className="home-list2">
                  <a
                    href="https://www.linkedin.com/company/inferix-labs/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link3"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://twitter.com/inferixgpu"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link4"
                  >
                    Twitter
                  </a>
                  <a
                    href="https://t.me/inferixgpu"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link5"
                  >
                    Telegram
                  </a>
                  <Link to="/" className="home-navlink8">
                    TikTok
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text23">
              © 2024 Inferix.inc - All rights reserved
            </span>
            <BackToTopButton />
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  )
}

export default Home
